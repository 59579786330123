import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

const EditLocationModal = ({ isOpen, toggle, data, onSave }) => {
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (data) {
      setFormData(data);
    }
  }, [data]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSave = () => {
    // Send all data (updated or not) back to the parent
    onSave(formData);
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Edit Location Details</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="latitude">Latitude</Label>
            <Input
              type="number"
              id="latitude"
              name="latitude"
              value={formData.latitude || ""}
              onChange={handleChange}
              required
              readOnly
            />
          </FormGroup>
          <FormGroup>
            <Label for="longitude">Longitude</Label>
            <Input
              type="number"
              id="longitude"
              name="longitude"
              value={formData.longitude || ""}
              onChange={handleChange}
              required
              readOnly
            />
          </FormGroup>
          <FormGroup>
            <Label for="narration">Narration</Label>
            <Input
              type="text"
              id="narration"
              name="narration"
              value={formData.narration || ""}
              onChange={handleChange}
              required
              readOnly
            />
          </FormGroup>
          <FormGroup>
            <Label for="city">City</Label>
            <Input
              type="text"
              id="city"
              name="city"
              value={formData.city || ""}
              onChange={handleChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="state">State</Label>
            <Input
              type="text"
              id="state"
              name="state"
              value={formData.state || ""}
              onChange={handleChange}
              required
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSave}>
          Save
        </Button>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditLocationModal;
