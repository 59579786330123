import { useEffect, useState } from "react";
import CommonTable from "../../Components/Common/CommonTable";
import MainHeading from "../../Components/Common/MainHeading";
import MainLayout from "../../Components/Layouts/MainLayout";
import { callApi } from "../../utility/useapi";
import { ApiUrl } from "../../utility/ApiUrls";
import CreateBannerModal from "./CreateBannerModal";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../Routes/ROUTES";
import ConfirmationModal from "../../utility/ConfirmationModal";
import { toast } from "react-toastify";

export default function Banner() {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [BannerList, setBannerList] = useState([]);
  const [pageStates, setPageStates] = useState({
    currentPage: 1,
    pageSize: 10,
    count: 0,
    totalPages: 0,
    tableLoader: false,
    SiteData: [],
    filterData: "",
  });
  const [selectedDeleteData, setSelectedDeleteData] = useState(null); // Track data for delete
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const toggleModal = () => setModalOpen(!modalOpen);
  const TableHeading = [
    {
      label: "#",
      value: "index",
    },
    {
      label: "Banner Name",
      value: "bannerName",
    },
    {
      label: "Type",
      value: "bannerType",
    },
    {
      label: "Start Date",
      value: "startDate",
    },
    {
      label: "End Date",
      value: "endDate",
    },
    {
      label: "Status",
      value: "  ",
    },
    // {
    //   label: "Status",
    //   title: "status",
    // },
  ];

  useEffect(() => {
    QRList();
  }, []);

  const QRList = () => {
    // ?filters=name%3Atest&searchQuery=test&pageNo=1&pageSize=10
    callApi(`${ApiUrl?.getBannerList}`).then((response) => {
      // console.log("Error...", response)
      setIsLoading(false);
      if (response?.status === true) {
        setBannerList(response?.data?.bannerData);
        setPageStates((prevState) => ({
          ...prevState,
          count: response?.data?.totalData,
          totalPages: response?.data?.totalPages,
        }));
      } else {
      }
    });
  };

  const PageChangeHandler = (newPage) => {
    setPageStates((prevState) => ({
      ...prevState,
      currentPage: newPage,
      tableLoader: true,
    }));

    callApi(
      `${ApiUrl?.getBannerList}?pageNo=${newPage}&pageSize=${pageStates?.pageSize}`
    ).then((response) => {
      setPageStates((prevState) => ({
        ...prevState,
        tableLoader: false,
      }));
      if (response?.status === true) {
        BannerList(response?.data?.batches);
        setPageStates((prevState) => ({
          ...prevState,
          count: response?.data?.totalData,
          totalPages: response?.data?.totalPages,
        }));
      } else {
        // Handle errors
      }
    });
  };

  const toggleConfirmationModal = () => {
    setIsConfirmationOpen(!isConfirmationOpen);
  };

  const ActionData = [
    {
      icon: <i class="fas fa-eye"></i>,
      label: "View",

      clickHandler: (data) => {
        navigate(ROUTES?.bannerDetials, { state: data });
        // handle clickHandler
      },
    },
    {
      icon: <i class="fas fa-trash"></i>,
      label: "Delete",

      clickHandler: (data) => {
        setSelectedDeleteData(data); // Set the selected data for deletion
        toggleConfirmationModal();
      },
    },
  ];
  const HeadingButtons = [
    {
      label: "Create Banner",
      function: () => {
        toggleModal();
      },
    },
  ];

  const fetchTableData = (
    searchQuery = "",
    newPage = 1,
    isDocVerified = ""
  ) => {
    setPageStates((prevState) => ({ ...prevState, tableLoader: true }));

    callApi(
      `${ApiUrl?.getBannerList}?filters=docVerified:${searchQuery?.isDocVerified};searchQuery:${searchQuery?.search}&pageNo=${newPage}&pageSize=${pageStates?.pageSize}`
    ).then((response) => {
      setPageStates((prevState) => ({ ...prevState, tableLoader: false }));

      if (response?.status === true) {
        setBannerList(response?.data?.bannerData || []); // Default to empty array if no users
        setPageStates((prevState) => ({
          ...prevState,
          count: response?.data?.totalData || 0,
          totalPages: response?.data?.totalPages || 1,
        }));
      } else {
        setBannerList([]); // Clear table data on error or empty response
        setPageStates((prevState) => ({
          ...prevState,
          count: 0,
          totalPages: 1,
        }));
        console.error("Error fetching data:", response?.message);
      }
    });
  };

  const deleteBlackSpot = () => {
    if (!selectedDeleteData?.id) return;

    callApi(`${ApiUrl?.deleteBanner}${selectedDeleteData.id}`, {
      method: "DELETE",
    }).then((response) => {
      setIsLoading(false);
      if (response?.status === true) {
        toast.success("Banner deleted successfully");
        QRList();
      } else {
        toast.error("Failed to delete the Banner");
      }
      toggleConfirmationModal();
    });
  };
  return (
    <>
      {/* <MainLayout>
      <MainHeading title={"Banner Management"} HeadingButtons={HeadingButtons} />
      <CommonTable TableHeading={TableHeading} ActionData={ActionData} />
    </MainLayout> */}
      <>
        <MainLayout>
          <MainHeading
            title={"Banner Management"}
            HeadingButtons={HeadingButtons}
          />
          <CommonTable
            TableHeading={TableHeading}
            TableData={BannerList}
            ActionData={ActionData}
            currentPage={pageStates?.currentPage}
            totalPages={pageStates?.totalPages}
            PageChangeHandler={PageChangeHandler}
            fetchTableData={fetchTableData} // Pass to handle search
          />
        </MainLayout>
        <CreateBannerModal
          isOpen={modalOpen}
          toggle={toggleModal}
          TableList={BannerList}
        />

        <ConfirmationModal
          isOpen={isConfirmationOpen}
          toggle={toggleConfirmationModal}
          onConfirm={deleteBlackSpot}
          message={`Are you sure you want to delete this black spot with ID: ${selectedDeleteData?.id}?`}
        />
      </>
    </>
  );
}
