import { Route, Routes, Navigate } from "react-router-dom";
import { ROUTES } from "./ROUTES";
import Login from "../Pages/Auth/Login";
import Dashboard from "../Pages/Dashboard";
import VerifiedUsers from "../Pages/VerifiedUsers";
import UnverifiedUsers from "../Pages/UnverifiedUsers";
import Category from "../Pages/Category";
import GenerateQR from "../Pages/GenerateQR";
import Complaints from "../Pages/Complaints";
import Banner from "../Pages/Banner";
import UserDetails from "../Pages/UserDetails";
import VehicleDetails from "../Pages/Vehicle";
import VehicleList from "../Pages/Vehicle";
import VehicleDetials from "../Pages/VehicalDetials";
import BannerDetials from "../Pages/Banner/BannerDetials";
import BlackSpot from "../Pages/BlackSpot";
import GuestUserDetails from "../Pages/GuestUserDetials";
import Cookies from "js-cookie";

// Mock function to check if user is logged in (replace with real authentication logic)
const isAuthenticated = () => {
  console.log("isAuthenticated", Cookies.get("viqr_token"));
  return Cookies.get("viqr_token"); // Example: checks for a token in localStorage
};

// Protected Route Wrapper
const ProtectedRoute = ({ element }) => {
  return isAuthenticated() ? element : <Navigate to={ROUTES?.login_route} />;
};

// Prevent access to login if already logged in
const PublicRoute = ({ element }) => {
  return isAuthenticated() ? <Navigate to={ROUTES?.dashboard} /> : element;
};

console.log("isAuthenticated", isAuthenticated());

const CommonRoutes = () => {
  const RoutesArray = [
    {
      path: ROUTES?.login_route,
      element: <PublicRoute element={<Login />} />, // Public route for login
    },
    {
      path: ROUTES?.dashboard,
      element: <ProtectedRoute element={<Dashboard />} />, // Protected route
    },
    {
      path: ROUTES?.verifiedUsers,
      element: <ProtectedRoute element={<VerifiedUsers />} />,
    },
    {
      path: ROUTES?.UnverifiedUsers,
      element: <ProtectedRoute element={<UnverifiedUsers />} />,
    },
    {
      path: ROUTES?.category,
      element: <ProtectedRoute element={<Category />} />,
    },
    {
      path: ROUTES?.generateQR,
      element: <ProtectedRoute element={<GenerateQR />} />,
    },
    {
      path: ROUTES?.complaints,
      element: <ProtectedRoute element={<Complaints />} />,
    },
    {
      path: ROUTES?.banner,
      element: <ProtectedRoute element={<Banner />} />,
    },
    {
      path: ROUTES?.userDetails,
      element: <ProtectedRoute element={<UserDetails />} />,
    },
    {
      path: ROUTES?.vehicle,
      element: <ProtectedRoute element={<VehicleList />} />,
    },
    {
      path: ROUTES?.vehicleDetials,
      element: <ProtectedRoute element={<VehicleDetials />} />,
    },
    {
      path: ROUTES?.bannerDetials,
      element: <ProtectedRoute element={<BannerDetials />} />,
    },
    {
      path: ROUTES?.blackSpot,
      element: <ProtectedRoute element={<BlackSpot />} />,
    },
    {
      path: ROUTES?.guestUserDetials,
      element: <ProtectedRoute element={<GuestUserDetails />} />,
    },
  ];
  return (
    <Routes>
      {RoutesArray?.map((route, index) => (
        <Route path={route?.path} key={index} element={route?.element} />
      ))}
    </Routes>
  );
};

export default CommonRoutes;
