import { data, useLocation, useNavigate } from "react-router-dom";
import MainLayout from "../../Components/Layouts/MainLayout";
import MainHeading from "../../Components/Common/MainHeading";
import DetailCard from "./Components/DetailCard";
import DetailTitle from "./Components/DetailTitle";
import DetailList from "./Components/DetailList";
import { useEffect, useState } from "react";
import { ApiUrl } from "../../utility/ApiUrls";
import { callApi } from "../../utility/useapi";
import moment from "moment";
import CommonTable from "../../Components/Common/CommonTable";
import { toast } from "react-toastify";
import ActivityModal from "../UserDetails/Components/ActivityModal";

export default function VehicleDetials() {
  const navigate = useNavigate();
  const location = useLocation();

  const [VehicalDetialsData, setVehicalDetialsData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [remark, setRemark] = useState("");
  const [vehicleID, setVehicleID] = useState(0); // Replace with actual vehicle ID if needed
  const [isLoading, setIsLoading] = useState(false);
  const [selectedComplaint, setSelectedComplaint] = useState(null);
  const [VehicalActivty, setVehicalActivty] = useState([]);
  const [ActivityData, setActivityData] = useState([]);
  const [modal, setModal] = useState(false);
  const [fillterData, setfillterData] = useState({
    searchdata: "",
    currentPage: 1,
    pageSize: 10,
    count: 0,
    totalPages: 0,
    tableLoader: false,
    SiteData: [],
    filterData: "",
  });

  // Fetch User Data
  useEffect(() => {
    if (location?.state?.id) {
      fetchUserDetails();
    } else {
      console.error("User GUID is missing in location state");
    }
  }, [location]);

  const toggleModal = () => {
    setModal(!modal);
  };

  const fetchUserDetails = async () => {
    try {
      const response = await callApi(
        `${ApiUrl?.vehicalDetials}vehicleId=${location?.state?.id}`
      );
      if (response?.status) {
        const user = response?.data || {};
        setVehicalDetialsData(response?.data);
      } else {
        console.error("Failed to fetch user details:", response?.message);
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const handleAction = (statusID, remark) => {
    setIsLoading(true);

    const payload = {
      vehicleID: location?.state?.id,
      statusID,
      remark,
    };

    const bodyOption = {
      method: "POST",
      data: payload,
    };

    callApi(`${ApiUrl?.RCVerifyAction}`, bodyOption).then((response) => {
      setIsLoading(false);

      if (response?.status === true) {
        toast.success("Action completed successfully");
        // Optional: Refresh user list or perform additional actions here
        userList();
      } else {
        toast.error("Failed to perform action");
        console.error("Error:", response?.message);
      }
    });
  };

  const handleReject = () => {
    if (remark.trim()) {
      handleAction(2, remark); // 2 for Reject
      setShowModal(false);
      setRemark("");
    } else {
      alert("Please enter a remark");
    }
  };

  const userList = () => {
    setIsLoading(true);
    callApi(`${ApiUrl?.getAllVehicle}`).then((response) => {
      setIsLoading(false);
      if (response?.status === true) {
        // Handle user list data
      } else {
        console.error("Failed to fetch user list");
      }
    });
  };

  const backButtonHandler = () => navigate(-1);
  const TableHeading = [
    {
      label: "#",
      value: "index",
    },
    {
      label: "logID",
      value: "logID",
    },
    {
      label: "action",
      value: "action",
    },
    {
      label: "logAddedAt",
      value: "logAddedAt",
    },
    {
      label: "platform",
      value: "platform",
    },
  ];

  const ActionData = [
    {
      icon: <i class="fas fa-eye"></i>,
      label: "View",
      clickHandler: (data) => {
        setSelectedComplaint(data);
        // setIsModalOpen(true); // Open the modal
        // fetchDocumentData(data.userGUID); // Assuming `data.id` is the user's ID
      },
    },
  ];

  useEffect(() => {
    fetchActivityLogsDetails();
  }, []);

  const fetchActivityLogsDetails = async () => {
    callApi(
      `${ApiUrl?.getVehicalActivty}${location?.state?.id}?filter=searchQuery:${fillterData.searchdata}&pageNo=${fillterData?.currentPage}&pageSize=${fillterData?.pageSize}`
    ).then((response) => {
      // console.log("Error...", response)
      setIsLoading(false);
      if (response?.status === true) {
        setVehicalActivty(response?.data?.logData);
      } else {
        toast.error(response?.message, {
          position: "top-right",
        });
      }
    });
  };

  const fetchActivityData = (userid) => {
    //  setIsLoading(true);
    callApi(`${ApiUrl?.getVehicalActivity}${userid}`).then((response) => {
      //  setIsLoading(false);
      if (response?.status === true) {
        setActivityData(response?.data?.logData); // Assuming the response contains document data
        setModal(true); // Open the modal
      } else {
        toast.error("Failed to fetch document data.");
      }
    });
  };

  return (
    <MainLayout>
      <MainHeading
        title={"Vehicle Detail"}
        backButtonHandler={backButtonHandler}
      />

      <div className="d-flex justify-content-between">
        <nav>
          <div className="nav nav-tabs border-0" id="nav-tab" role="tablist">
            <button
              className="btn btn-tabs px-5 rounded-0 active"
              data-bs-toggle="tab"
              data-bs-target="#tab-one"
              type="button"
              role="tab"
              aria-controls="nav-home"
              aria-selected="true"
            >
              Vehicle Details
            </button>
            <button
              className="btn btn-tabs px-5 rounded-0"
              data-bs-toggle="tab"
              data-bs-target="#tab-two"
              type="button"
              role="tab"
              aria-controls="nav-profile"
              aria-selected="false"
              tabIndex={-1}
            >
              Activity Logs
            </button>
          </div>
        </nav>
        <button
          onClick={() => fetchActivityData(VehicalDetialsData.id)}
          className="btn btn-primary"
        >
          Activity Log
        </button>
      </div>

      <div className="tab-content" id="myTabContent">
        <div
          className="tab-pane fade active show"
          id="tab-one"
          role="tabpanel"
          aria-labelledby="home-tab"
          tabIndex={0}
        >
          <div className="card manage-user-list">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between border-bottom mb-3">
                <h4 className="d-flex align-items-center mb-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <circle cx={10} cy={10} r={2} fill="#000000" />
                  </svg>
                  Vehicle Information
                </h4>
                <div>
                  <span
                    className={`badge ${
                      VehicalDetialsData?.status?.statusID == "3"
                        ? "bg-success"
                        : VehicalDetialsData?.status?.statusID == "2"
                        ? "bg-danger"
                        : "bg-warning"
                    }`}
                  >
                    {VehicalDetialsData?.status?.statusShortText ||
                      VehicalDetialsData?.status?.statusText}
                  </span>
                </div>
              </div>
              <ul className="row list-unstyled">
                <li className="col-12 col-md-6 col-lg-4">
                  <p>
                    <span className="text-muted fs-5 d-block">
                      Vehicle ID :-
                    </span>
                    {VehicalDetialsData?.id || "N/A"}
                  </p>
                </li>
                <li className="col-12 col-md-6 col-lg-4">
                  <p>
                    <span className="text-muted fs-5 d-block">
                      Vehicle Type :-
                    </span>
                    {VehicalDetialsData?.vehicleType || "N/A"}
                  </p>
                </li>
                <li className="col-12 col-md-6 col-lg-4">
                  <p>
                    <span className="text-muted fs-5 d-block">
                      Make/Model :-
                    </span>
                    {VehicalDetialsData?.makeModel || "N/A"}
                  </p>
                </li>
                <li className="col-12 col-md-6 col-lg-4">
                  <p>
                    <span className="text-muted fs-5 d-block">
                      Year of Manufacture :-
                    </span>
                    1600
                  </p>
                </li>
                <li className="col-12 col-md-6 col-lg-4">
                  <p>
                    <span className="text-muted fs-5 d-block">
                      Registration No. :-
                    </span>
                    {VehicalDetialsData?.vehicleNumber || "N/A"}
                  </p>
                </li>
                <li className="col-12 col-md-6 col-lg-4">
                  <p>
                    <span className="text-muted fs-5 d-block">
                      QR Code Status :-
                    </span>
                    <span className="badge bg-red-25 fw-normal rounded-pill">
                      deactived
                    </span>
                  </p>
                </li>
                <li className="col-12 col-md-6 col-lg-4">
                  <p>
                    <span className="text-muted fs-5 d-block">
                      RC Status :-
                    </span>
                    <span
                      className={`badge ${
                        VehicalDetialsData?.status?.id === 1
                          ? "bg-warning"
                          : VehicalDetialsData?.status?.id === 3
                          ? "bg-success"
                          : "bg-danger"
                      } fw-normal rounded-pill`}
                    >
                      {VehicalDetialsData?.status?.statusText ||
                        VehicalDetialsData?.status?.smallStatusText}
                    </span>
                  </p>
                </li>
              </ul>
              <h4 className="d-flex align-items-center mb-4 border-bottom pb-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <circle cx={10} cy={10} r={2} fill="#000000" />
                </svg>
                Owner Details
              </h4>

              {VehicalDetialsData &&
                VehicalDetialsData?.users?.map((res, index) => {
                  return (
                    <ul className="row list-unstyled" key="index">
                      <li className="col-12 col-md-6 col-lg-4">
                        <p>
                          <span className="text-muted fs-5 d-block">
                            Owner Name :-
                          </span>
                          {res?.name}
                        </p>
                      </li>
                      <li className="col-12 col-md-6 col-lg-4">
                        <p>
                          <span className="text-muted fs-5 d-block">
                            Contact No. :-
                          </span>
                          {res?.phone}
                        </p>
                      </li>
                      <li className="col-12 col-md-6 col-lg-4">
                        <p>
                          <span className="text-muted fs-5 d-block">
                            Email :-
                          </span>
                          {res?.email || "N/A"}
                        </p>
                      </li>
                      <li className="col-12 col-md-6 col-lg-4">
                        <p>
                          <span className="text-muted fs-5 d-block">
                            Aadhar Verification Status :-
                          </span>
                          {res?.isDocVerified == true ? (
                            <span className="badge bg-green-25 fw-normal rounded-pill">
                              Confirmed
                            </span>
                          ) : (
                            <span className="badge bg-yellow-25 fw-normal rounded-pill">
                              pending
                            </span>
                          )}
                        </p>
                      </li>
                      <li className="col-12 col-md-6 col-lg-4">
                        <p>
                          <span className="text-muted fs-5 d-block">
                            Family Members :-
                          </span>
                          --
                        </p>
                      </li>
                    </ul>
                  );
                })}

              <h4 className="d-flex align-items-center mb-4 border-bottom pb-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <circle cx={10} cy={10} r={2} fill="#000000" />
                </svg>
                QR Code Details
              </h4>
              <ul className="row list-unstyled">
                <li className="col-12 col-md-6 col-lg-4">
                  <p>
                    <span className="text-muted fs-5 d-block">QR ID :-</span>
                    {VehicalDetialsData?.id}
                  </p>
                </li>
                <li className="col-12 col-md-6 col-lg-4">
                  <p>
                    <span className="text-muted fs-5 d-block">
                      Generated On:-
                    </span>
                    1857
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="tab-two"
          role="tabpanel"
          aria-labelledby="home-tab"
          tabIndex={0}
        >
          <div className="card manage-user-list">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between border-bottom mb-3">
                <h4 className="d-flex align-items-center mb-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <circle cx={10} cy={10} r={2} fill="#000000" />
                  </svg>
                  QR Scanner Activity
                </h4>
              </div>
              <div className="table-head">
                <div className="position-relative">
                  <span className="search-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={20}
                      height={20}
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M16.7499 16.75L13.4874 13.4875M15.25 9.25C15.25 12.5637 12.5637 15.25 9.25 15.25C5.93629 15.25 3.25 12.5637 3.25 9.25C3.25 5.93629 5.93629 3.25 9.25 3.25C12.5637 3.25 15.25 5.93629 15.25 9.25Z"
                        stroke="#818898"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                  <input
                    type="text"
                    className="form-control search-control"
                    id="searchUsers"
                    placeholder="Search"
                  />
                </div>
                <div className="sorted d-flex align-items-center justify-content-end gap-3">
                  <h5>Filter By:</h5>
                  <div className="dropdown">
                    <button
                      className="btn dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Name
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={20}
                          height={20}
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M6.40039 8.19922L10.0004 11.7992L13.6004 8.19922"
                            stroke="#36394A"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                    </button>
                    <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item" href="#">
                          Category
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Status
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <CommonTable
                TableHeading={TableHeading}
                TableData={VehicalActivty}
                // ActionData={ActionData}
                currentPage={fillterData?.currentPage}
                totalPages={fillterData?.totalPages}
                // PageChangeHandler={PageChangeHandler}
              />
              <ActivityModal
                isOpen={modal}
                toggleActivity={toggleModal}
                activityData={ActivityData}
              />
            </div>
          </div>
        </div>

        <div className="row doc-verify">
          <div className="col-12">
            <div className="row">
              <div className="col-12 col-md-4">
                <img
                  src={`data:image/jpeg;base64,${VehicalDetialsData?.rcFrontPhotoPath}`}
                  className="doc-img"
                  alt=""
                />
              </div>
              <div className="col-12 col-md-4">
                <img
                  src={`data:image/jpeg;base64,${VehicalDetialsData?.rcBackPhotoPath}`}
                  className="doc-img"
                  alt=""
                />
              </div>
              {VehicalDetialsData?.status?.id == 3 ? (
                ""
              ) : (
                <div className="col-12 col-md-2 d-flex align-items-center gap-3">
                  <button
                    className="btn btn-danger w-100"
                    onClick={() => setShowModal(true)}
                  >
                    Reject
                  </button>
                  <button
                    className="btn btn-success w-100"
                    onClick={() => handleAction(3, "")} // 1 for Verify
                  >
                    Verify
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Modal */}
        {showModal && (
          <div className="modal show d-block" tabIndex="-1">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Enter Remark</h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => setShowModal(false)}
                  ></button>
                </div>
                <div className="modal-body">
                  <textarea
                    className="form-control"
                    value={remark}
                    onChange={(e) => setRemark(e.target.value)}
                    placeholder="Enter reason for rejection"
                    rows="3"
                  ></textarea>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={handleReject}
                  >
                    Reject
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </MainLayout>
  );
}
